import React from "react";
import './privacy.scss';
import { Link } from "react-router-dom";

const Privacy = () => {
    return(
        <div className="privacy_wrapper">
            <div className="privacy_content">
                <h1>Honkaidle.com - Privacy Policy</h1>
    <br />
    <br />
                <h2>Cookie Policy</h2>
                <br />
                <p>This site uses cookies to identify a returning user and to remember their guesses on this website.</p>
    <br />
    <br />
                <h2>Common ID Cookie</h2>
                <br />
                <p>This site uses cookies and similar tracking technologies such as the Common ID cookie to provide its services. Cookies are important devices for measuring advertising effectiveness and ensuring a robust online advertising industry. The Common ID cookie stores a unique user id in the first party domain and is accessible to our ad partners. This simple ID that can be utilized to improve user matching, especially for delivering ads to iOS and MacOS browsers.</p>

                <br /><br />

                <Link to="/">Click here to go back to Honkaidle</Link>
            </div>
        </div>
    )
}
export default Privacy;